import React, { Component } from 'react';
import ProfileContainer, {
  CCOProfileProps
} from '../container/ProfileContainer';
import { FormControl, Button, Tab, Tabs } from 'react-bootstrap';
import './OrganisationProfile.scss';
import Loader from '../../../components/Loader/Loader';
import GoogleMaps from '../../../components/GoogleMap/GoogleMap';
import 'react-image-crop/lib/ReactCrop.scss';
import ReactCrop from 'react-image-crop';
import { ReactComponent as UploadIcon } from '../../../images/Dashboard/upload-light.svg';
import swal from 'sweetalert';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { ReactComponent as CLoseIcon } from '../../../images/close.svg';
import { valid_postcode } from '../../Volunteering/components/VolunteeringCreate';

class OrganizationProfile extends Component<CCOProfileProps> {
  upload: any;
  uploadLogo: any;
  imageRef: any;
  logoImageRef: any;

  state = {
    bannerImage: '',
    oneLiner: '',
    description: '',
    bannerCrop: {
      x: 0,
      y: 0,
      width: 800,
      height: 300,
      aspect: 5 / 2
    },
    openCropModal: false,
    bannerCroppedImageUrl: '',

    logo: '',
    openLogoCropModal: false,
    logoCrop: {
      x: 0,
      y: 0,
      width: 120,
      height: 120,
      aspect: 2 / 2
    },
    logoCroppedImageUrl: '',
    id: '',
    isEdit: true,
    newAddress: {
      Line1: '',
      Line2: '',
      Line3: '',
      town: '',
      Postcode: ''
    },
    address: '',
    postCodeError: true,
    editAddress: false,
    socialLinks: [
      {
        type: 'facebook',
        link: ''
      },
      {
        type: 'twitter',
        link: ''
      },
      {
        type: 'insta',
        link: ''
      },
      {
        type: 'pinterest',
        link: ''
      }
    ]
  };

  loadScript = () => {
    const API_KEY = process.env.REACT_APP_GOOGLE_API;
    const url = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
    const s = document.createElement('script');
    s.src = url;
    document.head.appendChild(s);
  };

  componentDidMount() {
    this.loadScript();
  }

  setInitialState = () => {
    if (this.props.profile_info !== '') {
      this.setState({
        bannerImage:
          this.props.profile_info.charity.banner !== undefined
            ? this.props.profile_info.charity.banner +
              '?random_number=' +
              new Date().getTime()
            : '',
        logo:
          this.props.profile_info.charity.logo !== undefined
            ? this.props.profile_info.charity.logo +
              '?random_number=' +
              new Date().getTime()
            : '',
        oneLiner:
          this.props.profile_info.charity.oneLiner !== undefined
            ? this.props.profile_info.charity.oneLiner
            : '',
        description:
          this.props.profile_info.charity.description !== undefined
            ? this.props.profile_info.charity.description
            : '',
        id: this.props.profile_info.charity.id,
        address:
          this.props.profile_info.charity.deliveryAdress !== undefined
            ? this.props.profile_info.charity.deliveryAdress.Postcode
            : '',
        newAddress:
          this.props.profile_info.charity.deliveryAdress !== undefined
            ? this.props.profile_info.charity.deliveryAdress
            : this.props.profile_info.charity.deliveryAddress !== undefined
            ? this.props.profile_info.charity.deliveryAddress
            : this.state.newAddress,
        editAddress:
          this.props.profile_info.charity.deliveryAdress !== undefined ||
          this.props.profile_info.charity.deliveryAddress !== undefined
            ? true
            : false,
        socialLinks:
          this.props.profile_info.charity?.socialLinks || this.state.socialLinks
      });
    }
  };

  discard = () => {
    this.setState({
      bannerImage: '',
      oneLiner: '',
      description: '',
      bannerCrop: {
        x: 0,
        y: 0,
        width: 800,
        height: 300,
        aspect: 5 / 2
      },
      openCropModal: false,
      bannerCroppedImageUrl: '',

      logo: '',
      openLogoCropModal: false,
      logoCrop: {
        x: 0,
        y: 0,
        width: 120,
        height: 120,
        aspect: 2 / 2
      },
      logoCroppedImageUrl: '',
      id: '',
      isEdit: true,
      newAddress: {
        Line1: '',
        Line2: '',
        Line3: '',
        town: '',
        Postcode: ''
      },
      address: '',
      postCodeError: true,
      editAddress: false,
      socialLinks: [
        {
          type: 'facebook',
          link: ''
        },
        {
          type: 'twitter',
          link: ''
        },
        {
          type: 'insta',
          link: ''
        },
        {
          type: 'pinterest',
          link: ''
        }
      ]
    });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.props.profile_info !== '' &&
      this.props.profile_info !== prevProps.profile_info
    ) {
      this.setInitialState();
    }

    const { openCropModal, openLogoCropModal } = this.state;
    if (
      this.props.save_org_profile_succeeded &&
      this.props.save_org_profile_succeeded !==
        prevProps.save_org_profile_succeeded
    ) {
      if (this.props.setStep !== undefined) {
        this.props.setStep();
      }

      if (this.props.handleEdit !== undefined) {
        this.handleEdit();
      }

      this.setInitialState();
    }

    if (
      openLogoCropModal !== prevState.openLogoCropModal ||
      openCropModal !== prevState.openCropModal
    ) {
      document.getElementsByTagName('body')[0].style.overflowY =
        openLogoCropModal || openCropModal ? 'hidden' : 'auto';
    }
  }

  handleInputChange = (ev: any) => {
    this.setState({
      [ev.target.name]: ev.target.value
    });
  };

  onInputClick = (event: any) => {
    event.target.value = '';
  };

  onLogoInputClick = (event: any) => {
    event.target.value = '';
  };

  onSelectFile = (e: any) => {
    let _URL = window.URL || window.webkitURL;
    var self = this;
    if (e.target.files && e.target.files.length > 0) {
      const img: any = new Image();
      var file = e.target.files[0];

      let objectUrl = _URL.createObjectURL(e.target.files[0]);
      img.onload = function () {
        if (this.width >= 1200 && this.height >= 600) {
          const reader = new FileReader();

          reader.addEventListener(
            'load',
            () => {
              self.setState({
                bannerImage: reader.result,
                openCropModal: true
              });
            },
            false
          );
          reader.readAsDataURL(file);
        } else {
          // @ts-ignore
          swal({
            title: `You have uploaded a smaller image`,
            text: 'Please upload an image with more than 1200*600 resolution',
            icon: 'warning',
            dangerMode: true,
            // @ts-ignore
            button: {
              text: 'Okay',
              closeModal: true
            }
          });
        }
        _URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    }
  };

  onSelectLogoFile = (e: any) => {
    let _URL = window.URL || window.webkitURL;
    var self = this;
    if (e.target.files && e.target.files.length > 0) {
      const img: any = new Image();
      var file = e.target.files[0];

      let objectUrl = _URL.createObjectURL(e.target.files[0]);
      img.onload = function () {
        if (this.width >= 120 && this.height >= 120) {
          const reader = new FileReader();

          reader.addEventListener(
            'load',
            () => {
              self.setState({
                logo: reader.result,
                openLogoCropModal: true
              });
            },
            false
          );
          reader.readAsDataURL(file);
        } else {
          // @ts-ignore
          swal({
            title: `You have uploaded a smaller image`,
            text: 'Please upload an image with more than 120*120 resolution',
            icon: 'warning',
            dangerMode: true,
            // @ts-ignore
            button: {
              text: 'Okay',
              closeModal: true
            }
          });
        }
        _URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    }
  };

  handleClose = () => {
    this.setState({
      openCropModal: false
    });
  };

  handleCloseModal = () => {
    this.setState({
      openLogoCropModal: false
    });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onLogoImageLoaded = (image: any) => {
    this.logoImageRef = image;
  };

  onCropComplete = (bannerCrop: any) => {
    this.makeClientCrop(bannerCrop);
  };

  onLogoCropComplete = (logoCrop: any) => {
    this.makeLogoClientCrop(logoCrop);
  };

  onCropChange = (bannerCrop: any, percentCrop: any) => {
    this.setState({ bannerCrop });
  };

  onLogoCropChange = (logoCrop: any, percentCrop: any) => {
    this.setState({ logoCrop });
  };

  async makeClientCrop(bannerCrop: any) {
    if (this.imageRef && bannerCrop.width && bannerCrop.height) {
      const bannerCroppedImageUrl = this.getCroppedImg(
        this.imageRef,
        bannerCrop,
        'newFile.jpeg'
      );
      this.setState({ bannerCroppedImageUrl });
    }
  }

  async makeLogoClientCrop(logoCrop: any) {
    if (this.logoImageRef && logoCrop.width && logoCrop.height) {
      const logoCroppedImageUrl = this.getCroppedImg(
        this.logoImageRef,
        logoCrop,
        'newFile.jpeg'
      );
      this.setState({ logoCroppedImageUrl });
    }
  }

  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  }

  saveBannerCroppedImage = () => {
    this.setState({
      bannerImage: this.state.bannerCroppedImageUrl,
      openCropModal: false
    });
  };

  saveLogoCroppedImage = () => {
    this.setState({
      logo: this.state.logoCroppedImageUrl,
      openLogoCropModal: false
    });
  };

  componentWillUnmount(): void {
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
  }

  handleEdit = () => {
    this.setState({
      isEdit: true
    });
  };

  handleCkeckPostcode = (e: any) => {
    let test = valid_postcode(e.target.value);
    this.setState({
      postCodeError: test,
      address: e.target.value
    });
  };

  getAddress = () => {
    this.setState({
      newAddress: {
        ...this.state.newAddress,
        Postcode: this.state.address
      }
    });
    geocodeByAddress(this.state.address)
      .then((results) => this.getAdressss(results))
      .catch((error) =>
        this.setState({
          postCodeError: false
        })
      );
  };

  getAdressss = (result: any) => {
    getLatLng(result[0]).then((latLng) => {
      this.setState(
        {
          newAddress: {
            ...this.state.newAddress,
            latitude: latLng.lat,
            longitude: latLng.lng
          }
        },
        () => {
          this.setState({
            editAddress: true,
            newAddress: {
              ...this.state.newAddress,
              Line1: '',
              Line2: result[0].address_components[1].long_name,
              Line3:
                result[0].address_components[2].long_name +
                ', ' +
                result[0].address_components[3].long_name
            }
          });
        }
      );
    });
  };

  handleAddress = (ev: any) => {
    this.setState({
      newAddress: {
        ...this.state.newAddress,
        [ev.target.name]: ev.target.value
      }
    });
  };

  saveOrgProfile = () => {
    const {
      bannerImage,
      logo,
      oneLiner,
      description,
      newAddress,
      socialLinks
    } = this.state;
    let isRegisterFlow = localStorage.getItem('isRegisterFlow') === 'true';

    if (isRegisterFlow) {
      let payload = {
        banner: bannerImage,
        logo,
        oneLiner,
        description,
        id:
          localStorage.getItem('email') !== null
            ? localStorage.getItem('email')
            : this.props.email,
        socialLinks
      };
      this.props.updateOrgProfile(payload);
    } else {
      let payload = {
        banner: bannerImage,
        logo,
        oneLiner,
        description,
        id:
          localStorage.getItem('email') !== null
            ? localStorage.getItem('email')
            : this.props.email,
        deliveryAddress: {
          Line1: newAddress.Line1,
          Line2: newAddress.Line2,
          Line3: newAddress.Line3,
          Line4: '',
          PostCode: newAddress.Postcode
        },
        socialLinks
      };
      let flag =
        this.props.profile_info?.charity?.charityStatus?.toUpperCase() ===
        'UNDER REVIEW'
          ? '4'
          : '5';
      let isReapproval =
        this.props.profile_info?.charity?.charityStatus?.toUpperCase() ===
        'APPROVED'
          ? true
          : false;
      this.props.updateOrgProfileNew(payload, flag, isReapproval);
    }
  };

  handleSocialLink = (e: any) => {
    this.setState({
      socialLinks: [
        ...this.state.socialLinks.filter((sl) => sl.type !== e.target.name),
        {
          type: e.target.name,
          link: e.target.value
        }
      ]
    });
  };

  render() {
    const {
      bannerImage,
      description,
      oneLiner,
      logo,
      newAddress,
      editAddress,
      socialLinks
    } = this.state;
    const { profile_info, loading } = this.props;
    let isRegisterFlow = localStorage.getItem('isRegisterFlow') === 'true';
    let disable =
      bannerImage.length === 0 ||
      description.length === 0 ||
      oneLiner.length === 0 ||
      logo.length === 0 ||
      (!isRegisterFlow && editAddress && newAddress.Line1.length <= 0);

    let disableEdit = false;

    return (
      <div className="main-org-cont">
        {loading && <Loader />}

        <>
          <div className="organization-container">
            <div className="banner-img">
              <input
                id="myInput"
                type="file"
                ref={(ref) => (this.upload = ref)}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={this.onSelectFile}
                onClick={this.onInputClick}
                readOnly={disableEdit}
                disabled={disableEdit}
              />
              {bannerImage.length === 0 && (
                <div
                  className="upload-pic"
                  onClick={() => {
                    this.upload.click();
                  }}
                >
                  <UploadIcon className="img" />
                  <span>Upload Banner</span>
                </div>
              )}
              {bannerImage.length > 0 && (
                <div
                  className="img-cont"
                  style={{ backgroundImage: `url(${bannerImage})` }}
                >
                  {!disableEdit && (
                    <div
                      className="middle"
                      onClick={() => {
                        this.upload.click();
                      }}
                      style={!this.state.isEdit ? { display: 'none' } : {}}
                    >
                      <UploadIcon className="img" />
                      <span>Upload new Banner</span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="about-organization">
              <div className="part-1-org">
                <div className="org-part-1-1">
                  <div>
                    <input
                      id="myInputs"
                      type="file"
                      ref={(ref) => (this.uploadLogo = ref)}
                      style={{ display: 'none' }}
                      accept="image/*"
                      onChange={this.onSelectLogoFile}
                      onClick={this.onLogoInputClick}
                      readOnly={disableEdit}
                      disabled={disableEdit}
                    />

                    <label className="label">Logo</label>

                    {this.state.logo.length === 0 && (
                      <div
                        className="organization-logo"
                        onClick={() => {
                          this.uploadLogo.click();
                        }}
                      >
                        <div className="upload-pic">
                          <UploadIcon className="img" />
                          <span>Upload Logo</span>
                        </div>
                      </div>
                    )}

                    {this.state.logo.length > 0 && (
                      <div className="organization-logo">
                        <div
                          className="img-cont"
                          style={{
                            backgroundImage: `url(${this.state.logo})`
                          }}
                        >
                          {!disableEdit && (
                            <div
                              className="middle"
                              onClick={() => {
                                this.uploadLogo.click();
                              }}
                              style={
                                !this.state.isEdit ? { display: 'none' } : {}
                              }
                            >
                              <UploadIcon className="img" />
                              <span>Upload new logo</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {!isRegisterFlow && (
                    <div
                      className="location-conts"
                      style={{ marginTop: '1.7rem' }}
                    >
                      <label htmlFor="">Registered Address</label>
                      {profile_info?.charity?.latitude !== undefined &&
                        profile_info?.charity?.longitude !== undefined && (
                          <div className="map-container">
                            <GoogleMaps
                              lat={profile_info?.charity?.latitude}
                              lon={profile_info?.charity?.longitude}
                            />
                          </div>
                        )}

                      <div className="location">
                        <div>{profile_info?.charity?.Line1}</div>
                        <div>{profile_info?.charity?.Line2}</div>
                        <div>{profile_info?.charity?.Line3}</div>
                        <div>{profile_info?.charity?.Postcode}</div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="form-container">
                  <label className="label">
                    A one liner statement about your organisation
                  </label>
                  <FormControl
                    type="text"
                    name="oneLiner"
                    onChange={this.handleInputChange}
                    value={oneLiner}
                    className={!this.state.isEdit ? 'disable-edit' : ''}
                    readOnly={disableEdit}
                    disabled={disableEdit}
                  />
                  <div style={{ marginTop: '1rem' }}>
                    <label className="label text-label">Description</label>
                    <textarea
                      name="description"
                      value={description}
                      onChange={this.handleInputChange}
                      className={
                        !this.state.isEdit
                          ? 'disable-edit text-box '
                          : 'text-box'
                      }
                      readOnly={disableEdit}
                      disabled={disableEdit}
                      minLength={350}
                    />
                  </div>

                  {!isRegisterFlow && (
                    <div
                      className="name-form-group social-media-cont"
                      style={{ marginTop: '1rem' }}
                    >
                      <label className="label">
                        If you need physical deliveries to go to a different
                        address rather than the registered address, please enter
                        the new postcode here
                      </label>
                      <label className="label">Enter postcode</label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl
                          type="text"
                          name="address"
                          onChange={this.handleCkeckPostcode}
                          placeholder="E.g TW74HG"
                          value={this.state.address}
                          style={{ width: '30rem' }}
                          readOnly={disableEdit}
                          disabled={disableEdit}
                        />
                        {!disableEdit && (
                          <div
                            onClick={() =>
                              this.state.postCodeError &&
                              this.state.address.length > 0 &&
                              this.getAddress()
                            }
                            className={
                              this.state.postCodeError
                                ? 'get-address-btn'
                                : 'get-address-btn-disable'
                            }
                          >
                            Get Address
                          </div>
                        )}
                      </div>
                      {this.state.postCodeError !== null &&
                        !this.state.postCodeError && (
                          <label className="error-msg">
                            Enter valid postcode
                          </label>
                        )}

                      {editAddress && (
                        <div
                          className="form-conts"
                          style={{ marginTop: '1rem' }}
                        >
                          <div className="edit-form-group">
                            <label className="label">Address Line 1 *</label>
                            <FormControl
                              type="text"
                              value={newAddress.Line1}
                              onChange={this.handleAddress}
                              name="Line1"
                              readOnly={disableEdit}
                              disabled={disableEdit}
                            />
                          </div>
                          <div className="edit-form-group">
                            <label className="label">Address Line 2</label>
                            <FormControl
                              type="text"
                              value={newAddress.Line2}
                              onChange={this.handleAddress}
                              name="Line2"
                              readOnly={disableEdit}
                              disabled={disableEdit}
                            />
                          </div>
                          <div className="edit-form-group">
                            <label className="label">Area</label>
                            <FormControl
                              type="text"
                              value={newAddress.Line3}
                              onChange={this.handleAddress}
                              name="Line3"
                              readOnly={disableEdit}
                              disabled={disableEdit}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="social-media-cont">
                    <label className="label">Social media handles</label>
                    <Tabs
                      defaultActiveKey="facebook"
                      id="uncontrolled-tab-example"
                    >
                      {socialLinks
                        .sort((a: any, b: any) => a.type.localeCompare(b.type))
                        .map((sl: any, i: any) => (
                          <Tab
                            key={i}
                            tabClassName="social-link"
                            eventKey={sl.type}
                            title={sl.type.toUpperCase()}
                          >
                            <FormControl
                              type="text"
                              value={sl.link}
                              onChange={this.handleSocialLink}
                              name={sl.type}
                            />
                          </Tab>
                        ))}
                    </Tabs>
                  </div>
                </div>
              </div>

              {this.state.isEdit && !disableEdit && (
                <div
                  className="btn-container"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <Button
                    onClick={this.saveOrgProfile}
                    disabled={disable}
                    className={disable ? 'btn-disable' : 'btn-primary'}
                  >
                    {isRegisterFlow ? 'Save' : 'Update'}
                  </Button>
                  <Button
                    onClick={() => isRegisterFlow ? this.discard() : this.setInitialState()}
                    className="edit-btn"
                    style={{ margin: '0 2rem' }}
                  >
                    Discard
                  </Button>
                </div>
              )}
            </div>
          </div>
          {this.state.openCropModal && (
            <div className="cropper-modal">
              <CLoseIcon
                className="cropper-modal-close"
                onClick={this.handleClose}
              />
              {this.state.bannerImage && (
                <div className="cropper-cont">
                  <ReactCrop
                    src={this.state.bannerImage}
                    crop={this.state.bannerCrop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />

                  <Button onClick={this.saveBannerCroppedImage}>Crop</Button>
                </div>
              )}
            </div>
          )}

          {this.state.openLogoCropModal && (
            <div className="cropper-modal">
              <CLoseIcon
                className="cropper-modal-close"
                onClick={this.handleCloseModal}
              />
              {this.state.logo && (
                <div className="cropper-cont">
                  <ReactCrop
                    src={this.state.logo}
                    crop={this.state.logoCrop}
                    onImageLoaded={this.onLogoImageLoaded}
                    onComplete={this.onLogoCropComplete}
                    onChange={this.onLogoCropChange}
                  />

                  <Button onClick={this.saveLogoCroppedImage}>Crop</Button>
                </div>
              )}
            </div>
          )}
        </>
      </div>
    );
  }
}

export default ProfileContainer(OrganizationProfile);
