import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ReactPlayer from 'react-player';

class CommonQuestions extends Component {
  render() {
    return (
      <div>
        <Header activeTab="about" />
        <div className="about-us-cont">
          <div className="about-us-header">
            <div className="title">Help Center</div>
            <div className="about-header">
              We have addressed some common questions about how to use the
              service and have put together a series of short support videos you
              can watch.
            </div>

            <div
              className="block-cont"
              style={{
                paddingTop: '1rem',
                borderTop: '.2rem solid var(--bg-1)'
              }}
            >
              <div
                className="block"
                style={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between'
                }}
              >
                <div className="content">
                  <span>About HelpBritain</span> <br />
                  <ReactPlayer
                    url="https://youtu.be/jYJeT2wtH8A"
                    width="100%"
                  />
                </div>

                <div className="content">
                  <span>How to register with HelpBritain</span> <br />
                  <ReactPlayer
                    url="https://youtu.be/ronbl61gJTE"
                    width="100%"
                  />
                </div>

                <div className="content">
                  <span>Why to register with HelpBritain</span> <br />
                  <ReactPlayer
                    url="https://youtu.be/hH6eT63QAEk"
                    width="100%"
                  />
                </div>

                <div className="content">
                  <span>How to create an amazon wishlist:</span> <br />
                  <ReactPlayer
                    url="https://youtu.be/T4tme8qK9EQ"
                    width="100%"
                  />
                </div>

                <div className="content">
                  <span>How to create a select wishlist:</span> <br />
                  <ReactPlayer
                    url="https://youtu.be/Vr8S1fJZYNo"
                    width="100%"
                  />
                </div>

                <div className="content">
                  <span>How to request volunteers:</span> <br />
                  <ReactPlayer
                    url="https://youtu.be/lNGSsMI02Sk"
                    width="100%"
                  />
                </div>
              </div>
            </div>

            <div
              className="block-cont"
              style={{
                paddingTop: '1rem',
                borderTop: '.2rem solid var(--bg-1)'
              }}
            >
              <div className="block" style={{ width: '100%' }}>
                <div className="content">
                  <span>Donating</span> <br />
                  <br />
                  <ul>
                    <li>
                      Will I get a receipt after I donate? <br />
                      If you donate an item through Amazon or eBay, those
                      retailers will send you a receipt directly. If you donate
                      an supermarket voucher then you will receive a receipt
                      from us to the email address you supply.
                    </li>
                    <li>
                      Can I add GiftAid to a donation? <br />
                      At the moment we are sorry that this isn’t yet possible
                      but we are working to add this in future.
                    </li>
                    <li>
                      {' '}
                      The User Content does not contain any defamatory,
                      libelous, offensive, racist, misleading, indecent or
                      otherwise unlawful material or material which is an
                      invasion of privacy;
                    </li>
                    <li>
                      How do I know my item was received? <br />
                      In order to comply with data protection rules, we are
                      unable to provide updates on when items have been
                      physically delivered, but the retailers on the platform
                      usually deliver within 1-3 days.
                    </li>
                  </ul>
                  <br />
                  <br />
                  <span>Volunteering</span> <br />
                  <br />
                  <ul>
                    <li>
                      How do I see whether my volunteering request was accepted?
                      <br />
                      At HelpBritain, we create the initial connection between
                      organisations and volunteers, so acceptance will come via
                      the email address you provide. You should always talk to
                      the organisation directly before volunteering, so that you
                      know exactly what’s expected and can ensure you’re happy
                      with the arrangements.
                    </li>
                  </ul>
                  <br />
                  <br />
                  <span>Registering my charity/organisation</span> <br />
                  <br />
                  <ul>
                    <li>
                      What do you do with my personal data?
                      <br />
                      We don’t maintain any personal data at HelpBritain - this
                      is why we don’t ask you to log in or create an account in
                      order to donate. When you donate, you are given the option
                      to add your email address to a contact list for the
                      relevant organisation if you would like to receive
                      updates, or just as a one-off to receive a thank you. The
                      charities & organisations who use our platform are all
                      really keen to say thank you for your generosity!
                    </li>
                  </ul>
                  <br />
                  <br />
                  <span>My Data</span> <br />
                  <br />
                  <ul>
                    <li>
                      What do you do with my personal data?
                      <br />
                      We don’t maintain any personal data at HelpBritain - this
                      is why we don’t ask you to log in or create an account in
                      order to donate. When you donate, you are given the option
                      to add your email address to a contact list for the
                      relevant organisation if you would like to receive
                      updates, or just as a one-off to receive a thank you. The
                      charities & organisations who use our platform are all
                      really keen to say thank you for your generosity!
                    </li>
                  </ul>
                  <br />
                  <br />
                  <span>HelpBritain</span> <br />
                  <br />
                  <ul>
                    <li>
                      Is HelpBritain a registered charity?
                      <br />
                      We are not a registered charity. HelpBritain is run as a
                      separate not-for-profit division by Ovyo Ltd.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CommonQuestions;
