import React, { Component } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

class TermsAndConditions extends Component {
  render() {
    return (
      <div className="">
        <Header activeTab="about" />
        <div className="about-us-cont">
          <div className="about-us-header">
            <div className="title">Terms and conditions</div>
            <div className="block-cont">
              <div className="block" style={{ width: '100%' }}>
                <div className="content">
                  <span>General</span> <br />
                  <br />
                  These terms and conditions of service (the “Terms”) outline
                  the rules and regulations for the use of HelpBritain's website
                  and apps. Please read them carefully. By accessing this
                  website, you accept these terms and conditions in full. Do not
                  continue to use HelpBritain's website if you do not accept all
                  of the terms and conditions stated on this page. We may update
                  these Terms from time to time – it is your responsibility to
                  review these Terms for updates when you access the website.
                  Terms last updated: 16th September 2020.
                  <br />
                  <br />
                  <span>Glossary</span> <br />
                  <br />
                  The following terminology applies to these Terms, Privacy
                  Statement and Disclaimer Notice and any or all Agreements:
                  <ul>
                    <li>
                      {' '}
                      "Client", "You" and "Your" refers to you, the person
                      accessing this website and accepting the Company's terms
                      and conditions, whether donors or charities.
                    </li>
                    <li>
                      "The Company", “HelpBritain”, "Ourselves", "We", "Our" and
                      "Us", refers to the owner of the website, Ovyo Ltd.
                    </li>
                    <li>
                      "Party", "Parties" or "Us", refers to both the Client and
                      ourselves, or either the Client or ourselves.
                    </li>
                    <li>
                      “The Platform” refers to the HelpBritain website, apps and
                      associated components and services.
                    </li>
                    <li>
                      “Charities” or “Organisations” refers to the charities
                      with pages on the HelpBritain platform who are looking for
                      donations of goods and/or time/services by volunteers.
                    </li>
                    <li>
                      “Wishlist” refers to lists of items such as goods or
                      vouchers created by Charities for the purpose of receiving
                      those items as donations.
                    </li>
                    <li>
                      “Retail Partners” refers to companies who sell and
                      distribute goods which are made available to donate on the
                      Platform.
                    </li>
                  </ul>
                  Any use of the above terminology or other words in the
                  singular, plural, capitalisation and/or he/she or they, are
                  taken as interchangeable and
                  <br />
                  <br />
                  <span> Therefore as referring to same. Charities </span>
                  <br />
                  <br />
                  The Platform allows you to donate to and receive donations for
                  any Charity listed on the website which has created one or
                  more Wishlists. A Charity listed on HelpBritain must be a
                  registered UK charity with a registered UK address.
                  HelpBritain will verify the identity of a Charity prior to
                  completing registration to the extent possible. All Charities
                  are listed at HelpBritain’s sole discretion however
                  HelpBritain is not responsible for the activities of any
                  Charity or the content displayed by the Charity on the
                  Platform. It is your responsibility as a user of the Platform
                  to ensure that the Charity on a given page is the one you wish
                  to support.
                  <br />
                  <br />
                  As a Charity user, you agree that HelpBritain can collect
                  donations on your behalf and facilitate the distribution of
                  those donations as required.
                  <br />
                  <br />
                  <span>Donations You agree that </span>
                  <br />
                  <br />
                  HelpBritain can take and store limited personal information
                  about you when making a donation.
                  <br />
                  <br /> You agree that HelpBritain can take and store
                  reasonable personal information about you and the Charity when
                  registering to receive donations, including that the Charity
                  address and other User Content that you upload will be made
                  publicly available.
                  <br />
                  <br /> After donations are made, all further dealings are
                  solely between you and the Charity to which you donated.{' '}
                  <br />
                  <br />
                  The Charity pages may state that Charities will use donations
                  for a specific purpose however HelpBritain cannot guarantee
                  that this will be the case. If you have any concerns or
                  questions on how any donations will be used, you should
                  contact the Charity directly.
                  <br />
                  <br /> HelpBritain is not liable in any way for the condition
                  or performance of goods received from Retail Partners as part
                  of a donation, or for their timely or accurate delivery.{' '}
                  <br />
                  <br />
                  The ‘Select’ vouchers are provided as codes sent by email to
                  the email address you register with us and are subject to
                  Redemption Terms which can be accessed for reference at
                  <a
                    href=" https://www.select-your-reward.co.uk/pages/termsandconditions"
                    target="_blank"
                    className="linksss"
                  >
                    https://www.select-your-reward.co.uk/pages/termsandconditions
                  </a>
                  <br />
                  <br />
                  Subject to the requirements of applicable laws, no donations
                  will be refunded for any reason. At HelpBritain’s sole
                  discretion, we may seek to obtain the written consent from a
                  Charity to refund a donation where this was for ‘Select’
                  vouchers, in which case it will only be refunded to you if the
                  full written consent of the Charity who received the donation
                  is received within one month and once the Charity has directed
                  the relevant funds to HelpBritain. For any donations from
                  other Retail Partners, HelpBritain does not process the
                  payment and as such any refund must be sought directly from
                  the relevant Retail Partner. <br />
                  <br />
                  We do accept voluntary gratuity from donors in consideration
                  of using the Platform. All donations are subject to third
                  party payment processing fees which are explained further at{' '}
                  <a href="www.helpbritain.org.uk/fees" target="_blank">
                    www.helpbritain.org.uk/fees
                  </a>
                  <br />
                  <br />
                  <span> Unauthorised donations</span>
                  <br />
                  <br />
                  When you make a donation, the transaction is final and not
                  disputable unless unauthorised use of your payment card or
                  other payment method is proved. Once you become aware of
                  fraudulent use of your card or other payment service, or if it
                  is lost or stolen, you must immediately notify your card /
                  service provider in accordance with its reporting rules.
                  <br />
                  <br />
                  HelpBritain will never email, phone or otherwise contact you
                  and ask you to provide all of your payment details.
                  <br />
                  <br />
                  <span>Retail Partners</span>
                  <br />
                  <br />
                  Many Retail Partners on the Platform will directly fulfil a
                  donation, meaning that HelpBritain does not take any money
                  from the donor. For such donations, it is the user’s sole
                  responsibility to ensure that the donation amount and delivery
                  address are accurate prior to completing the transaction.
                  HelpBritain cannot assist with remedying any errors in either
                  the donation amount or the delivery address for goods
                  purchased through Amazon or eBay.
                  <br />
                  <br />
                  <span> Volunteering </span>
                  <br />
                  <br />
                  You agree that HelpBritain can take, send and store limited
                  personal information about you when volunteering your
                  availability for a Charity.
                  <br />
                  <br />
                  After an offer of volunteering availability is made, all
                  further dealings are solely between you and the Charity to
                  which you donated.
                  <br />
                  <br />
                  HelpBritain is not liable for any communications regarding
                  volunteering activities or the execution and performance of
                  any volunteering activities. It is your sole responsibility to
                  ensure that volunteering activities you engage in or allow
                  others to engage in are safe, risk assessed and suitable.
                  <br />
                  <br />
                  <span>Service Use</span>
                  <br />
                  <br />
                  You warrant and represent that you will:
                  <br />
                  <br />
                  <ul>
                    <li>Use the Platform in a legal manner;</li>

                    <li>
                      At no time act in any way or upload any content that is
                      inaccurate or misleading, including that you will not
                      misrepresent your identity, address or affiliation with
                      any Charity;
                    </li>

                    <li>
                      Ensure any content you upload (including all text and
                      images) conforms to these Terms; Not use the Platform to
                      conduct, forward or display any lotteries, raffles,
                      contests or any form of gambling;
                    </li>

                    <li>
                      Not use the Platform to send any messages to people who do
                      not wish to receive them; Not attempt to modify, adapt,
                      translate, sell, reverse engineer, decompile or
                      disassemble any portion of the Platform;
                    </li>
                  </ul>
                  <br />
                  You warrant and represent that you will not attempt to
                  interfere with or disrupt the HelpBritain Platform, including
                  not taking part in or allowing to take part the: Bypassing of
                  any firewalls;
                  <br /> <br />
                  <ul>
                    <li>
                      {' '}
                      Introduction of any computer virus or similar malicious
                      code or instructions or similar variants;
                    </li>
                    <li>
                      Circumvention of security to access any parts of the site
                      to which you are not authorized (including scanning
                      networks with intent to evaluate and/or breach security,
                      whether or not the intrusion is successful);
                    </li>
                    <li>Performance of Denial-of-service attacks;</li>
                    <li>
                      {' '}
                      Any other criminal, unlawful, negligent or malicious acts
                      including destruction of data and invasion of privacy.
                    </li>
                  </ul>
                  <br />
                  <span> Warranty</span>
                  <br /> <br />
                  We do not warrant or represent that donations will be used for
                  any particular purpose and shall not be responsible for any
                  dissatisfaction you may have towards a Charity’s use of any
                  donation made through the Platform or any misuse or non-use of
                  such donations by a Charity. HelpBritain has no liability to
                  donors whatsoever for any use or misuse of donations made on
                  the Platform.
                  <br /> <br />
                  HelpBritain will from time to time modify the Platform which
                  may include adding, removing and modifying services without
                  notice. All new services and features shall be subject to
                  these same Terms.
                  <br /> <br />
                  <span> Cookies </span>
                  <br /> <br />
                  We and our partners may employ the use of cookies. By using
                  HelpBritain’s website you consent to the use of cookies in
                  accordance with{' '}
                  <a href="/privacy" className="linksss">
                    HelpBritain’s privacy policy
                  </a>
                  . Most of the modern-day interactive websites use cookies to
                  enable us to retrieve user details for each visit. Cookies are
                  used in some areas of our site to enable the functionality of
                  this area and ease of use for those people visiting.
                  <br /> <br />
                  <span> License </span>
                  <br /> <br />
                  Unless otherwise stated, HelpBritain and/or it’s licensors own
                  the intellectual property rights for all material on
                  HelpBritain. All intellectual property rights are reserved.
                  You may view and/or print pages from
                  http://www.helpbritain.org.uk for your own personal use
                  subject to restrictions set in these terms and conditions.
                  <br /> <br />
                  You must not sell, rent or sub-license material from
                  www.helpbritain.org.uk or copy material from it other than
                  with the purpose of publicising / sharing on social media. You
                  must not create frames around our web pages or use other
                  techniques that alter in any way the visual presentation or
                  appearance of our website.
                  <br /> <br />
                  <span> User inputs and comments </span>
                  <br /> <br />
                  Certain parts of this website offer the opportunity for users
                  to post and exchange images and messages which could include
                  opinions, information, material and data (“User Content”).
                  <br /> <br />
                  HelpBritain does not screen, edit, publish or review User
                  Content prior to their appearance on the website and User
                  Content does not reflect the views or opinions of HelpBritain,
                  its agents or affiliates. User Content reflects the view and
                  opinion of the person who posts such view or opinion. To the
                  extent permitted by applicable laws HelpBritain shall not be
                  responsible or liable for the User Content or for any loss
                  cost, liability, damages or expenses caused and or suffered as
                  a result of any use of and/or posting of and/or appearance of
                  the User Content on this website.
                  <br /> <br />
                  HelpBritain reserves the right to monitor all User Content and
                  to remove any User Content which it considers in its absolute
                  discretion to be inappropriate, misleading, offensive or
                  otherwise in breach of these Terms and Conditions.
                  <br /> <br />
                  You warrant and represent that:
                  <br /> <br />
                  <ul>
                    <li>
                      You are entitled to post the User Content on our website
                      and have all necessary licenses and consents to do so;{' '}
                    </li>
                    <li>
                      The User Content does not infringe any intellectual
                      property right, including without limitation copyright,
                      patent or trademark, or other proprietary right of any
                      third party;
                    </li>
                    <li>
                      {' '}
                      The User Content does not contain any defamatory,
                      libelous, offensive, racist, misleading, indecent or
                      otherwise unlawful material or material which is an
                      invasion of privacy;
                    </li>
                    <li>
                      The Comments will not be used to solicit or promote
                      business or custom or present commercial activities or
                      unlawful activity.
                    </li>
                    <li>
                      You hereby grant to HelpBritain a non-exclusive
                      royalty-free license to use, reproduce, edit and authorize
                      others to use, reproduce and edit any of your User Content
                      in any and all forms, formats or media.
                    </li>
                  </ul>
                  <br />
                  <span>Removal of links from our website</span>
                  <br /> <br />
                  If you find any link on our website or any linked web site
                  objectionable for any reason, you may contact us about this.
                  We will consider requests to remove links but will have no
                  obligation to do so or to respond directly to you.
                  <br />
                  Whilst we endeavour to ensure that the information on this
                  website is correct, we do not warrant its completeness or
                  accuracy; nor do we commit to ensuring that the website
                  remains available or that the material on the website is kept
                  up to date.
                  <br /> <br />
                  <span>Data extraction</span> 
                  <br /> <br />
                  You agree not to copy, mirror, scrape, mine, frame, index,
                  sell or distribute (and not to use or allow the use of any
                  tool, program, script, browser extension or other technique
                  including bots, robots, spiders and scrapers and any similar
                  tools or methods) or otherwise gather or extract any of the
                  data or other content from the Platform. HelpBritain may take
                  any measures we deem necessary to block access where we
                  believe these Terms have been or will be breached.
                  <br /> <br />
                  You acknowledge and agree that for any actual or threatened
                  breach of these Terms, damages may be an inadequate remedy and
                  we shall be entitled to seek an injunction or any other
                  equitable relief for such breach, without prejudice to any
                  other rights and remedies it may have.
                  <br /> <br />
                  If HelpBritain requests that you destroy any data you have
                  gathered or extracted from the Platform, you agree that you
                  will comply with such request promptly and certify the same.
                  <br /> <br />
                  <span> Termination We reserve the </span>
                  <br /> <br />
                  right to immediately remove access, delete any pages and
                  permanently deny you access to the Platform without notice in
                  the event you fail to comply with our Terms. We may also
                  periodically suspend or delete Charity pages if the relevant
                  Charity is no longer considered active on the Platform.
                  <br /> <br />
                  You may stop using the Platform at any time without notifying
                  HelpBritain. These Terms will continue to apply to your
                  historical activities.
                  <br /> <br />
                  <span>Protecting your account </span>
                  <br /> <br />
                  You are responsible for maintaining the confidentiality and
                  security of your password to prevent others gaining access to
                  your page and information. If you become aware of any access
                  to your account not authorised by you, we recommend
                  immediately changing your password.
                  <br /> <br />
                  <span>Copyright </span>
                  <br /> <br />
                  All content on the Platform is owned by HelpBritain and is
                  protected under intellectual property law. You may copy
                  content solely for your own non-commercial use.
                  <br /> <br />
                  <span> Disclaimer </span>
                  <br /> <br />
                  To the maximum extent permitted by applicable law, we exclude
                  all representations, warranties and conditions relating to our
                  website and the use of this website (including, without
                  limitation, any warranties implied by law in respect of
                  satisfactory availability, quality, security, fitness for
                  purpose and/or the use of reasonable care and skill). The
                  Platform is made public on an “as available” and “as is” basis
                  and your use of it is at your sole risk. Nothing in this
                  disclaimer will:
                  <br /> <br />
                  <ul>
                    <li>
                      limit or exclude our or your liability for death or
                      personal injury resulting from negligence;
                    </li>
                    <li>
                      limit or exclude our or your liability for fraud or
                      fraudulent misrepresentation;
                    </li>
                    <li>
                      limit any of our or your liabilities in any way that is
                      not permitted under applicable law; or
                    </li>
                    <li>
                      exclude any of our or your liabilities that may not be
                      excluded under applicable law or any statutory rights.
                    </li>
                  </ul>
                  <br />
                  The limitations and exclusions of liability set out in this
                  Section and elsewhere in this disclaimer: (a) are subject to
                  the preceding paragraph; and (b) govern all liabilities
                  arising under the disclaimer or in relation to the subject
                  matter of this disclaimer, including liabilities arising in
                  contract, in tort (including negligence) and for breach of
                  statutory duty.
                  <br /> <br />
                  To the extent that the website and the information and
                  services on the website are provided free of charge, and its
                  availability and content is impacted by numerous factors
                  outside of our reasonable control, we will not be liable for
                  any loss or damage of any nature.
                  <br /> <br />
                  You expressly agree that HelpBritain is not liable for any
                  losses relating to any lost or corrupt data or computer
                  equipment, loss of revenue, sales, profits or opportunity,
                  loss of staff, management or personal time, loss of goodwill
                  or brand damage, even if you have advised us as to the
                  possibility of such damages.
                  <br /> <br />
                  <span>Governing law </span>
                  <br /> <br />
                  These Terms and any disputes, either contractual or
                  non-contractual, arising from your use of the Platform are
                  governed by English law and subject to the jurisdiction of
                  English courts. The locale of any arbitration will be London,
                  England.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TermsAndConditions;
