import React, { Component } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

class HowItWorks extends Component {
  render() {
    return (
      <div>
        <Header activeTab="about" />
        <div className="about-us-cont">
          <div className="about-us-header">
            <div className="title">How It Works</div>
            <div className="about-header">
              HelpBritain lets UK registered charities create wishlists of goods
              they would like to receive and lets the generous British public
              donate goods and volunteer their time. We don’t charge any fees.
              Here’s how it works.
            </div>
            <div className="block-cont">
              <div className="block" style={{ width: '100%' }}>
                <div className="content">
                  If you’re a UK registered charity, you can register the
                  charity with HelpBritain. We need you to upload your logo,
                  enter your address details and tell us a little bit about what
                  you do. After you submit your registration, our team runs a
                  few checks and then you’re all set to start creating
                  wishlists. At the moment, you can create three types of
                  wishlists, each of which you can name and provide some more
                  info so people know what you’re trying to achieve:
                  <ul>
                    <li>
                      Amazon wishlist (you need to create this on Amazon.co.uk
                      and then paste the link into HelpBritain)
                    </li>
                    <li>
                      Select vouchers ongoing wishlist: receive donations
                      ongoing
                    </li>
                    <li>
                      Select vouchers target wishlist: target a specific amount
                      Each of your wishlists gets its own link that you can
                      share with supporters on social media, on whatsapp or in
                      newsletters etc.
                    </li>
                  </ul>
                  <br />
                  Members of the public can visit HelpBritain and find your
                  charity and your wishlists using a number of search and filter
                  tools including specifically searching for charities near
                  them, or offering a particular type of support close to their
                  heart. Once someone has found a charity they’d like to donate
                  to, they simply click ‘donate’ next to the appropriate item.
                  For Amazon wishlists, this then takes them to Amazon to
                  complete the purchase. For Select vouchers, HelpBritain
                  processes the donation and emails the voucher code direct to
                  the charity the same day so it can be used immediately. We
                  don’t require donors to register because we are not interested
                  in taking people’s personal data that we don’t need – this
                  keeps it simple.
                  <br />
                  <br />
                  If there’s a part of HelpBritain that you’re not sure exactly
                  how to use, please check out our support page.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default HowItWorks;
