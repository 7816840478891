import React, { Component } from 'react';
import './Footer.scss';
import FooterContainer, { FooterProps } from './FooterContainer';

class Footer extends Component<FooterProps> {
  state = {
    openTermsModal: false
  };

  openTermsConditionModal = () => {
    this.setState({
      openTermsModal: !this.state.openTermsModal
    });
  };

  render() {
    return (
      <div className="footer-cont">
        <div className="part1">
          <div className="part1-contents">
            <div className="content-cont logo-cont">
              <div className="logo">
                <img
                  src={require('../../images/cutLogo.png')}
                  alt=""
                  className="image"
                />
                <div className="logo-text">
                  HELP
                  <br />
                  BRITAIN
                  <br />
                  .ORG.UK
                </div>
              </div>
              <div style={{ margin: '1rem 0' }}>
                Practical help where it’s needed most
              </div>
            </div>
            <div className="content-cont">
              <div className="title">Organisations to support</div>
              <div className="items">
                {this.props.services !== '' &&
                  this.props.services?.uniq
                    ?.slice(0, 7)
                    .sort((a:any, b:any) => a.localeCompare(b))
                    .map((d: any, i: any) => (
                      <div className="item" key={i}>
                        <a
                          href={`/all-causes?category=${d}`}
                          className="item"
                          onClick={() =>
                            localStorage.setItem('filterSelected', d)
                          }
                        >
                          {d}
                        </a>
                      </div>
                    ))}
              </div>
            </div>
            <div className="content-cont">
              <div className="title">Ways to volunteer</div>
              <div className="items">
                <a href="/volunteering?category=all" className="item">
                  Search for needs near you
                </a>
              </div>
            </div>
            <div className="content-cont">
              <div className="title">Find out more</div>
              <div className="items">
                <a href="/howitworks" className="item">
                  How it works
                </a>
                <a href="/about" className="item">
                  About Us
                </a>
                <a href="/support" className="item">
                  Help Center
                </a>
                <a href="/fees" className="item">
                  Fees
                </a>
                <a href="/terms_and_conditions" className="item">
                  Terms
                </a>
                <a href="/privacy" className="item">
                  Privacy
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="part2">
          <div className="part2-contents">
            <div className="copyrights">© Ovyo Ltd 2020</div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterContainer(Footer);

//https://fontawesome.com/v4.7.0/cheatsheet/
