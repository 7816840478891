import React, { Component } from "react";
import { Chart } from "react-google-charts";
import "./Dashboard.scss";

class Dashboard extends Component {
  render() {
    return (
      <div>
        <div className="dashboard-cont">
          <div className="dashboard-header">Dashboard</div>
          <div className="chart-cont">
            <div className="charts">
              <div className="title">Active Campaigns</div>
              <Chart
                width={"100%"}
                height={"89%"}
                chartType="Bar"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Year", "Sales", "Expenses", "Profit"],
                  ["2014", 1000, 400, 200],
                  ["2015", 1170, 460, 250],
                  ["2016", 660, 1120, 300],
                  ["2017", 1030, 540, 350],
                ]}
                options={{
                  chartArea: { width: "50%" },
                  colors: ["#14e528", "#fc4ae8", "#6583d9"],
                }}
                rootProps={{ "data-testid": "2" }}
              />
            </div>

            <div className="charts">
              <div className="title">Active Campaigns</div>
              <Chart
                width={"100%"}
                height={"89%"}
                chartType="GeoChart"
                data={[
                  ["Country", "Popularity"],
                  ["Germany", 200],
                  ["United States", 300],
                  ["Brazil", 400],
                  ["Canada", 500],
                  ["France", 600],
                  ["RU", 700],
                ]}
                options={{
                  colors: ["#14e528", "#fc4ae8", "#6583d9"],
                }}
                // Note: you will need to get a mapsApiKey for your project.
                // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                mapsApiKey="YOUR_KEY_HERE"
                rootProps={{ "data-testid": "1" }}
              />
            </div>

            <div className="charts">
              <div className="title">Total Population</div>
              <Chart
                width={"100%"}
                height={"89%"}
                chartType="BarChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["City", "2010 Population", "2000 Population"],
                  ["New York City, NY", 8175000, 8008000],
                  ["Los Angeles, CA", 3792000, 3694000],
                  ["Chicago, IL", 2695000, 2896000],
                  ["Houston, TX", 2099000, 1953000],
                  ["Philadelphia, PA", 1526000, 1517000],
                ]}
                options={{
                  chartArea: { width: "50%" },
                  colors: ["#14e528", "#fc4ae8"],
                  hAxis: {
                    title: "Total Population",
                    minValue: 0,
                    fontName: "Baloo Da 2",
                  },
                  vAxis: {
                    title: "City",
                    fontName: "Baloo Da 2",
                  },
                }}
                rootProps={{ "data-testid": "4" }}
              />
            </div>

            <div className="charts">
              <div className="title">My Daily Activities</div>
              <Chart
                width={"100%"}
                height={"89%"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Task", "Hours per Day"],
                  ["Work", 11],
                  ["Eat", 2],
                  ["Commute", 2],
                  ["Watch TV", 2],
                  ["Sleep", 7],
                ]}
                rootProps={{ "data-testid": "1" }}
              />
            </div>

            <div className="charts charts-lg">
              <div className="title">Popularity</div>
              <Chart
                width={"98%"}
                height={"89%"}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["x", "dogs"],
                  [0, 0],
                  [1, 10],
                  [2, 23],
                  [3, 17],
                  [4, 18],
                  [5, 9],
                  [6, 11],
                  [7, 27],
                  [8, 33],
                  [9, 40],
                  [10, 32],
                  [11, 35],
                ]}
                options={{
                  colors: ["#14e528", "#fc4ae8", "#6583d9"],
                  hAxis: {
                    title: "Time",
                  },
                  vAxis: {
                    title: "Popularity",
                  },
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
