import React, { Component } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import './MyProfile.scss';
import ProfileContainer, {
  CCOProfileProps
} from '../container/ProfileContainer';
import SweetAlert from 'react-bootstrap-sweetalert';
import OrganisationProfile from './OrganisationProfile';

class MyProfile extends Component<CCOProfileProps> {
  state = {
    openResetPasswordModal: false
  };

  openResetModal = () => {
    this.setState({
      openResetPasswordModal: true
    });
  };

  onConfirm = () => {};
  onCancel = () => {
    this.setState({
      openResetPasswordModal: false
    });
  };

  render() {
    let is_cco_logged_in = localStorage.getItem('__is_loggedIn');
    return (
      <div className="my-profile-cont">
        {this.props.fetch_profile_succeeded && (
          <form className="form-cont">
            <div className="profile-pic">
              <div>
                {this.props.profile_info.firstName[0] +
                  this.props.profile_info.lastName[0]}
              </div>
            </div>

            <div style={{ marginTop: '2rem' }}>
              <div className="name-cont">
                <span className="text-1">NAME : </span>
                <span>
                  {this.props.profile_info.firstName +
                    ' ' +
                    this.props.profile_info.lastName}
                </span>
              </div>
              <div className="name-cont">
                <span className="text-1">EMAIL : </span>
                <span>{this.props.profile_info.email}</span>
              </div>

              {is_cco_logged_in && (
                <>
                  <div className="name-cont">
                    <span className="text-1">Charity :</span>
                    <span>
                      {this.props.profile_info !== undefined &&
                        this.props.profile_info?.charity?.CharityName}
                    </span>
                  </div>
                  <div className="name-cont">
                    <span className="text-1">Registered Charity Number :</span>
                    <span>
                      {this.props.profile_info !== undefined &&
                        this.props.profile_info?.charity
                          ?.RegisteredCharityNumber}
                    </span>
                  </div>
                  <div className="name-cont">
                    <span className="text-1">Registered Address :</span>
                    <span>
                      {this.props.profile_info?.charity?.Line1 +
                        ', ' +
                        this.props.profile_info?.charity?.Line2 +
                        ', ' +
                        this.props.profile_info?.charity?.Line3 +
                        ', ' +
                        this.props.profile_info?.charity?.Postcode}
                    </span>
                  </div>
                </>
              )}
            </div>
          </form>
        )}

        {is_cco_logged_in && <>
          <div className="my-profile-heading">Organisation Profile</div>
          <OrganisationProfile />
        </>}
        {this.state.openResetPasswordModal && (
          <SweetAlert
            custom
            showCancel={false}
            showCloseButton
            showConfirm={false}
            confirmBtnText="Yes"
            cancelBtnText="No"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="light"
            title="Change Your Password.!"
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          >
            <div className="form-cont">
              <div className="form-group">
                <label htmlFor="" className="label">
                  Current Password
                </label>
                <FormControl type="password" />
              </div>
              <div className="form-group">
                <label htmlFor="" className="label">
                  New Password
                </label>
                <FormControl type="password" />
              </div>
              <div className="btn-cont">
                <Button className="btn-default btn-form">Cancel</Button>
                <Button className="btn-primary btn-form">Save</Button>
              </div>
            </div>
          </SweetAlert>
        )}
      </div>
    );
  }
}

export default ProfileContainer(MyProfile);
